import { Index, uuid } from '@interacta-shared/util';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { emptyAttachmentsList } from '@modules/post/models/attachment/attachment.utils';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { Delta } from 'quill/core';
import {
    AIContentGenerationType,
    AIContextType,
    AIFeedbackDetailType,
    AIMessageType,
    AIRole,
    AIToneOfVoiceType,
    AttachmentsListType,
    HistoryThread,
    MAIN_THREAD,
} from '../models/ai.model';
import {
    AIActionsAPI,
    AICommunityActionsAPI,
    AIContentGenerationActionsAPI,
    AIGuideActionsAPI,
    AIPostActionsAPI,
} from './ai-api.actions';
import { AIActions } from './ai.actions';

export type AIState = {
    isPanelOpen: boolean;
    isPanelAnimating: boolean;
    cachedAttachments: IAttachment[];
    cachedAttachmentsLists: {
        [key in AttachmentsListType]: IListAttachments;
    };
    activeContext: AIContextType | null;
    contexts: {
        [key in AIContextType]: AIContextState;
    };
};

export type AIContextState = {
    contextId: number | null;
    sessionId: number | null;
    threads: HistoryThread;
    workflowEnabled: boolean | null;
    contextType: AIContextType | null;
    activeThread: Index | null;
    contentGeneration: AIContentGenerationContextState;
};

export type AIGeneratedContentDetail = {
    delta: Delta | null;
    tone: AIToneOfVoiceType | null;
    feedbackId?: number;
    like?: boolean;
    feedbacks?: AIFeedbackDetailType[];
    feedbackText?: string;
    confirmed?: boolean;
};

export type AIGeneratedContent = {
    isFetching: boolean;
    hasErrors: boolean;
    contents: AIGeneratedContentDetail[];
};

export type AIContentGenerationContextState = {
    [key in AIContentGenerationType]: AIGeneratedContent;
} & { initialContent: Delta | null | undefined };

const initialAIContentGenerationState: AIContentGenerationContextState = {
    initialContent: null,
    [AIContentGenerationType.TONE_OF_VOICE]: {
        isFetching: false,
        hasErrors: false,
        contents: [],
    },
    [AIContentGenerationType.EXPAND]: {
        isFetching: false,
        hasErrors: false,
        contents: [],
    },
    [AIContentGenerationType.SUMMARIZE]: {
        isFetching: false,
        hasErrors: false,
        contents: [],
    },
};

const initialAIContextState: AIContextState = {
    contextId: null,
    sessionId: null,
    threads: {
        [MAIN_THREAD]: {
            title: null,
            history: {},
            isFetching: false,
            draftQuestion: null,
        },
    },
    contextType: null,
    workflowEnabled: null,
    activeThread: null,
    contentGeneration: initialAIContentGenerationState,
};

const initialAIState: AIState = {
    isPanelOpen: false,
    isPanelAnimating: false,
    cachedAttachments: [],
    cachedAttachmentsLists: {
        ['post']: emptyAttachmentsList(),
        ['comment']: emptyAttachmentsList(),
        ['postFilePicker']: emptyAttachmentsList(),
    },
    activeContext: null,
    contexts: {
        [AIContextType.POST]: initialAIContextState,
        [AIContextType.COMMUNITY]: initialAIContextState,
        [AIContextType.GUIDE]: initialAIContextState,
        [AIContextType.CONTENT_GEN]: initialAIContextState,
    },
};

export const AIReducer = createReducer<AIState>(
    initialAIState,

    on(AIActions.setActiveContext, (state, props) => {
        const isSameActiveContext = (): boolean =>
            state.activeContext === props.contextType &&
            state.contexts[state.activeContext].contextId === props.contextId;

        return {
            ...state,
            activeContext: props.contextType,
            cachedAttachments: isSameActiveContext()
                ? [...state.cachedAttachments]
                : [],
            cachedAttachmentsLists: isSameActiveContext()
                ? { ...state.cachedAttachmentsLists }
                : {
                      ['post']: emptyAttachmentsList(),
                      ['comment']: emptyAttachmentsList(),
                      ['postFilePicker']: emptyAttachmentsList(),
                  },
            contexts: {
                ...state.contexts,
                [props.contextType]:
                    state.contexts[props.contextType].contextId !==
                    props.contextId
                        ? {
                              ...initialAIContextState,
                              contextId: props.contextId,
                              contextType: props.contextType,
                              workflowEnabled: props.workflowEnabled,
                              activeThread: props.threadId ?? MAIN_THREAD,
                              contentGeneration: {
                                  ...initialAIContentGenerationState,
                                  initialContent:
                                      props.initialContentGeneration,
                              },
                          }
                        : state.contexts[props.contextType],
            },
        };
    }),

    on(AIActions.switchActiveContext, (state, props) => {
        return {
            ...state,
            activeContext: props.contextType,
        };
    }),

    on(AIActions.addThreadInActiveContext, (state, props) => {
        return produce(state, (draft) => {
            if (state.activeContext) {
                const context = draft.contexts[state.activeContext];

                context.activeThread = props.threadId;

                if (!context.threads[props.threadId]) {
                    context.threads[props.threadId] = {
                        isFetching: false,
                        title: props.attachment?.name ?? null,
                        history: {
                            [uuid()]: {
                                id: -1,
                                role: AIRole.USER,
                                message: props.attachment?.name ?? '',
                                messageType: AIMessageType.ATTACHMENT,
                                timestamp: new Date(),
                                attachmentId: props.threadId,
                            },
                        },
                        draftQuestion: null,
                    };

                    context.threads[MAIN_THREAD].history[uuid()] = {
                        id: -1,
                        role: AIRole.USER,
                        message: props.attachment?.name ?? '',
                        messageType: AIMessageType.ATTACHMENT,
                        timestamp: new Date(),
                        attachmentId: props.threadId,
                    };
                }
            }
        });
    }),

    on(AIActions.setActiveThreadInActiveContext, (state, props) => {
        return produce(state, (draft) => {
            if (state.activeContext) {
                const context = draft.contexts[state.activeContext];

                context.activeThread = props.threadId;
            }
        });
    }),

    on(AIActions.getResponseFromAI, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];
            const index = props.threadId ?? MAIN_THREAD;

            context.threads[index].isFetching = true;
            context.threads[index].draftQuestion = null;

            context.threads[index].history[props.clientId] = {
                ...context.threads[index].history[props.clientId],
                role: AIRole.USER,
                message: props.question,
                messageType: AIMessageType.STANDARD,
                timestamp: props.timestamp,
            };
        });
    }),

    on(AIActionsAPI.sendFeedbackDetailSuccess, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];
            const index = props.threadId ?? MAIN_THREAD;

            context.threads[index].history[props.key].like = props.like;
            context.threads[index].history[props.key].feedbacks =
                props.feedbacks;
            context.threads[index].history[props.key].feedbackText = props.text;
        });
    }),

    on(AIActionsAPI.sendFeedbackSuccess, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];
            const index = props.threadId ?? MAIN_THREAD;

            context.threads[index].history[props.key].like = props.like;
        });
    }),

    on(AIPostActionsAPI.getPostResponseFromAISuccess, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[AIContextType.POST];
            const index = props.threadId ?? MAIN_THREAD;

            context.sessionId = props.sessionId;
            context.threads[index].isFetching = false;
            context.threads[index].history[props.clientId].id =
                props.questionId;
            context.threads[index].history[uuid()] = props.answer;
        });
    }),

    on(AIPostActionsAPI.getPostResponseFromAIError, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[AIContextType.POST];
            const index = props.threadId ?? MAIN_THREAD;

            context.sessionId = props.sessionId;
            context.threads[index].isFetching = false;
            context.threads[index].history[uuid()] = {
                id: -1,
                role: AIRole.MODEL,
                message: props.message,
                messageType: props.messageType,
                timestamp: new Date(),
            };

            if (
                props.messageType === AIMessageType.FETCH_FILE_ERROR ||
                props.messageType === AIMessageType.UNSUPPORTED_TYPE
            ) {
                context.threads[index].hasErrors = true;
            }
        });
    }),

    on(
        AICommunityActionsAPI.getCommunityResponseFromAISuccess,
        (state, props) => {
            return produce(state, (draft) => {
                const context = draft.contexts[AIContextType.COMMUNITY];
                const index = props.threadId ?? MAIN_THREAD;

                context.sessionId = props.sessionId;
                context.threads[index].isFetching = false;
                context.threads[index].history[props.clientId].id =
                    props.questionId;
                context.threads[index].history[uuid()] = props.answer;
            });
        },
    ),

    on(
        AICommunityActionsAPI.getCommunityResponseFromAIError,
        (state, props) => {
            return produce(state, (draft) => {
                const context = draft.contexts[AIContextType.COMMUNITY];
                const index = props.threadId ?? MAIN_THREAD;

                context.sessionId = props.sessionId;
                context.threads[index].isFetching = false;
                context.threads[index].history[uuid()] = {
                    id: -1,
                    role: AIRole.MODEL,
                    message: props.message,
                    messageType: props.messageType,
                    timestamp: new Date(),
                };
            });
        },
    ),

    on(AIGuideActionsAPI.getGuideResponseFromAISuccess, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[AIContextType.GUIDE];
            const index = props.threadId ?? MAIN_THREAD;

            context.sessionId = props.sessionId;
            context.threads[index].isFetching = false;
            context.threads[index].history[props.clientId].id =
                props.questionId;
            context.threads[index].history[uuid()] = props.answer;
        });
    }),

    on(AIGuideActionsAPI.getGuideResponseFromAIError, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[AIContextType.GUIDE];
            const index = props.threadId ?? MAIN_THREAD;

            context.sessionId = props.sessionId;
            context.threads[index].isFetching = false;
            context.threads[index].history[uuid()] = {
                id: -1,
                role: AIRole.MODEL,
                message: props.message,
                messageType: props.messageType,
                timestamp: new Date(),
            };
        });
    }),

    on(AIActions.fetchAttachmentsList, (state, props) => {
        return {
            ...state,
            cachedAttachmentsLists: {
                ...state.cachedAttachmentsLists,
                [props.entityType]: {
                    ...state.cachedAttachmentsLists[props.entityType],
                    isLoading: true,
                    attachments: props.initial
                        ? []
                        : [
                              ...state.cachedAttachmentsLists[props.entityType]
                                  .attachments,
                          ],
                },
            },
        };
    }),

    on(AIActionsAPI.fetchAttachmentListSuccess, (state, props) => {
        return {
            ...state,
            cachedAttachmentsLists: {
                ...state.cachedAttachmentsLists,
                [props.entityType]: {
                    attachments: [
                        ...(props.initial
                            ? []
                            : state.cachedAttachmentsLists[props.entityType]
                                  .attachments),
                        ...props.list.attachments,
                    ],
                    isLoading: false,
                    pageTokenInfo: props.list.pageTokenInfo,
                    totalCount: props.list.totalCount,
                },
            },
        };
    }),

    on(AIActionsAPI.fetchAttachmentListError, (state, props) => {
        return produce(state, (draft) => {
            draft.cachedAttachmentsLists[props.entityType].isLoading = false;
            draft.cachedAttachmentsLists[props.entityType].isError = true;
        });
    }),

    on(AIActions.startNewConversation, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];

            context.threads[MAIN_THREAD].history[uuid()] = {
                id: -1,
                role: AIRole.USER,
                message: props.requestMessage,
                messageType: AIMessageType.EXAMPLE,
                timestamp: new Date(),
            };
            context.threads[MAIN_THREAD].history[uuid()] = {
                id: -1,
                role: AIRole.MODEL,
                message: props.responseMessage,
                messageType: AIMessageType.EXAMPLE,
                timestamp: new Date(),
            };
        });
    }),

    on(AIActions.setAlreadyAnimated, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];
            const index = props.threadId ?? MAIN_THREAD;

            if (context.threads[index].history[props.clientId])
                context.threads[index].history[props.clientId].alreadyAnimated =
                    true;
        });
    }),

    on(AIActions.saveDraftQuestion, (state, props) => {
        return produce(state, (draft) => {
            const context = draft.contexts[props.contextType];
            const index = props.threadId ?? MAIN_THREAD;

            if (context.threads[index])
                context.threads[index].draftQuestion = props.draftQuestion;
        });
    }),

    on(
        AIActionsAPI.getAttachmentDetailSuccess,
        AIActions.cacheAttachmentDetail,
        (state, props) => {
            const ids = state.cachedAttachments.map((a) => a.id);

            const newAttachments = props.attachments.filter(
                (a) => !ids.includes(a.id),
            );

            return {
                ...state,
                cachedAttachments: [
                    ...state.cachedAttachments,
                    ...newAttachments,
                ],
            };
        },
    ),

    on(AIActions.toggleAIPanel, (state, props) => ({
        ...state,
        isPanelOpen: props.isOpen,
    })),

    on(AIActions.setIsPanelAnimating, (state, props) => ({
        ...state,
        isPanelAnimating: props.isAnimating,
    })),

    on(AIActions.setGeneratedContentInActiveContext, (state, props) => {
        return produce(state, (draft) => {
            if (state.activeContext) {
                const context = draft.contexts[state.activeContext];

                context.contentGeneration.initialContent = new Delta(
                    props.generatedContent?.ops,
                );
            }
        });
    }),

    on(AIActions.fetchContentGeneration, (state, props) => {
        return produce(state, (draft) => {
            if (state.activeContext) {
                const context = draft.contexts[state.activeContext];

                const contentGeneration =
                    context.contentGeneration[props.contentGenerationType];

                contentGeneration.isFetching = true;
                contentGeneration.contents = [];
                contentGeneration.hasErrors = false;
            }
        });
    }),

    on(
        AIContentGenerationActionsAPI.fetchGeneratedContentSuccess,
        (state, props) => {
            return produce(state, (draft) => {
                if (state.activeContext) {
                    const context = draft.contexts[state.activeContext];
                    const contentGeneration =
                        context.contentGeneration[props.generatedContentType];

                    contentGeneration.isFetching = false;
                    contentGeneration.hasErrors = false;

                    props.contents.forEach((c) =>
                        contentGeneration.contents.push({
                            delta: c.text,
                            tone: c.toneType ?? null,
                        }),
                    );
                }
            });
        },
    ),

    on(
        AIContentGenerationActionsAPI.fetchGeneratedContentError,
        (state, props) => {
            return produce(state, (draft) => {
                if (state.activeContext) {
                    const context = draft.contexts[state.activeContext];
                    const contentGeneration =
                        context.contentGeneration[props.contentGenerationType];

                    contentGeneration.isFetching = false;
                    contentGeneration.hasErrors = true;
                    contentGeneration.contents = [];
                }
            });
        },
    ),

    on(
        AIContentGenerationActionsAPI.sendGeneratedContentFeedbackSuccess,
        (state, props) => {
            return produce(state, (draft) => {
                if (state.activeContext) {
                    const context = draft.contexts[state.activeContext];
                    const contentGeneration =
                        context.contentGeneration[props.contentGenerationType];

                    const content = props.toneType
                        ? contentGeneration.contents.find(
                              (c) => c.tone === props.toneType,
                          )
                        : contentGeneration.contents[0];

                    if (content) {
                        content.like = props.like;
                        content.feedbackId = props.feedbackId;
                        content.feedbacks = props.feedbacks;
                        content.feedbackText = props.note;
                        content.confirmed = props.confirmed;
                    }
                }
            });
        },
    ),
);
