import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
} from '@angular/core';
import { Index, isDefined } from '@interacta-shared/util';
import { AIContextType, MAIN_THREAD } from '@modules/ai/models/ai.model';
import {
    selectActiveContextType,
    selectActiveHistory,
    selectActiveThread,
} from '@modules/ai/store/ai.selectors';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    Observable,
    Subject,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'interacta-ai-panel',
    templateUrl: './ai-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelComponent implements OnDestroy {
    @Input() aiPostAttachmentEnabled = false;

    readonly activeContextType$: Observable<AIContextType>;
    readonly activeThread$: Observable<Index>;

    sideThread$ = new BehaviorSubject<Index | null>(null);
    activeThreadName$ = new BehaviorSubject<string | null>(null);
    destroy$ = new Subject<void>();

    AIContextType = AIContextType;
    MAIN_THREAD = MAIN_THREAD;

    ANIMATION_DURATION = 300;

    constructor(private store: Store) {
        this.activeContextType$ = this.store
            .select(selectActiveContextType)
            .pipe(filter(isDefined));

        this.activeThread$ = this.store.select(selectActiveThread);

        this.activeThread$
            .pipe(
                takeUntil(this.destroy$),
                distinctUntilChanged(),
                switchMap((value) =>
                    this.store.select(selectActiveHistory(value)).pipe(
                        filter(isDefined),
                        map((result) => ({ value, result })),
                    ),
                ),
            )
            .subscribe(({ value, result }) => {
                if (value !== MAIN_THREAD) {
                    this.sideThread$.next(value);
                }
                this.activeThreadName$.next(result.title);
            });
    }
    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
